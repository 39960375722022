<template>
  <Portlet
    title="Actioncams"
    icon="camera"
    class="actioncamList"
  >
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <template v-if="actioncamsAvailable">
        <table class="defaultTable">
          <colgroup>
            <col>
            <col>
            <col width="120">
          </colgroup>
          <thead>
            <tr>
              <th class="font-weight-bold">
                Device Name
              </th>
              <th class="font-weight-bold">
                Cam Name
              </th>
              <th class="font-weight-bold">
                Cam Lane ID
              </th>
              <th class="font-weight-bold">
                Spot ID
              </th>
              <th class="font-weight-bold">
                {{ $t('options') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in actioncams"
              :key="'tr' + index"
            >
              <td v-if="item.name">
                {{ item.name }}
              </td>
              <td>
                {{ item.actionCamName }}
              </td>
              <td>
                {{ item.actionCamId }}
              </td>
              <td>
                {{ item.actionCamSpotId }}
              </td>
              <td v-if="item.uuid">
                <router-link
                  :to="{path: pathToDetails(item.uuid) }"
                  class="btn btn-sm btn-primary btn-block"
                >
                  <font-awesome-icon
                    class="mr-2"
                    icon="edit"
                  />
                  <span>{{ $t('configure') }}</span>
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </template>
      <template v-else>
        {{ $t('noDataAvailable') }}
      </template>
    </template>
  </Portlet>
</template>

<script>
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js'

export default {
  name: "ActioncamList",
  mixins: [
    dateTimeMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      actioncams: null
    };
  },
  computed: {
    actioncamsAvailable () {
      if (this.actioncams == null) {
        return false;
      }
      if (this.actioncams.length == null) {
        return false;
      }
      if (this.actioncams.length === 0) {
        return false;
      }
      return true;
    }
  },
  async created () {
    await this.getActioncams();
  },
  methods: {
    pathToDetails (deviceId) {
      return `/installation/${ this.installationId }/actioncam/${ deviceId }`;
    },
    async getActioncams () {
      await this.axios.get(`/Actioncam/GetActioncams/${ this.installationId }`)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.actioncams = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>

<style>
.actioncamList .m-portlet__body {
  min-height: 118px;
}
</style>
